// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faArrowRight as falFaArrowRight } from "@fortawesome/pro-light-svg-icons/faArrowRight";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faArrowRight as farFaArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";


import {faArrowRight as fasFaArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import {faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";


/**
 * Add solid icons
 */
library.add(fasFaArrowRight, fasFaTimes);

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes, farFaArrowRight);

/**
 * Add light icons
 */
library.add(falFaArrowRight);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
